import { ListBoxProps, Option } from "../types"
import Svg from "../svg"
import { getListedOptionClasses } from "@common/hooks/useDropdown"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import { joinClassNames } from "@common/lib/util"

interface ListBoxOptionProps extends Omit<ListBoxProps, "options"> {
  option: Option
  selected?: boolean
  showSelectedIcon?: boolean
  isHoverable?: boolean
  showCheckedIcon?: boolean
}
//📌 ADD ImageWithFallback ->  https://github.com/currents-ai/common-react-components/issues/268
export default function ListBoxOption({
  option,
  selected,
  onSelect,
  disabled,
  showSelectedIcon = true,
  isHoverable,
  showCheckedIcon,
}: ListBoxOptionProps) {
  const handleListOptionClick = (e: any) => {
    // e.stopPropagation()
    if (!option.disabled || !disabled) {
      if (onSelect) onSelect(option, e)
    }
  }

  return (
    // button causes button in button warnings and hydration errors
    //eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      key={option.key}
      onClick={option?.disabled || disabled ? undefined : handleListOptionClick}
      //disabled={option?.disabled || disabled}
      className={getListedOptionClasses(
        !!selected,
        option.disabled || disabled,
        isHoverable
      )}
    >
      <ListboxOptionContent
        icon={option.icon}
        display={option.display}
        selected={selected}
        showSelectedIcon={showSelectedIcon}
        showCheckedIcon={showCheckedIcon}
      />
    </div>
  )
}

export function ListboxOptionContent({
  icon,
  display,
  selected,
  showSelectedIcon,
  disabled,
  showCheckedIcon,
}: any) {
  return (
    <>
      <div
        className={joinClassNames(
          "flex justify-start items-center gap-2",
          selected && showSelectedIcon ? "w-[calc(100%_-_16px)]" : "w-full"
        )}
      >
        {showCheckedIcon && (
          <CheckboxStyled checked={selected} disabled={disabled} />
        )}
        {typeof icon == "string" ? (
          <Svg
            classes="w-4 h-4 text-secondary"
            tooltipContent={undefined}
            name={icon}
            strokeWidth={2}
          />
        ) : (
          icon
        )}
        {display && (
          <span
            className={` block truncate my-auto text-primary w-full ${
              selected ? "font-semibold " : ""
            }`}
          >
            {display}
          </span>
        )}
      </div>
      {selected && showSelectedIcon ? (
        <div className="my-auto flex justify-end">
          <Svg
            tooltipContent={undefined}
            name={"check"}
            classes="w-4 h-4"
            strokeWidth={2}
          />
        </div>
      ) : null}
    </>
  )
}
