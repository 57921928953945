import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"
import BarChartSkeleton from "@common/skeletons/skeletonComponents/barChartSkeleton"

const SearchSkeleton = () => {
  const commonStyles = {
    borderRadius: 6,
    height: 20,
    width: "100%",
  };

  const renderSearchItem = () => (
    <div className="pt-2 mt-1 flex flex-col justify-between gap-2 items-baseline border-t  p-2">
      <BoxSkeleton styles={{ ...commonStyles, width: "20%" }} />
      <BoxSkeleton styles={{ ...commonStyles, width: "80%" }} />
      <BoxSkeleton styles={{ ...commonStyles, width: "10%" }} />
    </div>
  );

  return (
    <div className="h-full overflow-hidden flex justify-center gap-1">
      <div className="w-2/3 m-1 rounded-md flex flex-col justify-start overflow-y-hidden">
        <div className="flex justify-center gap-2 px-10 py-4">
          {[...Array(3)].map((_, index) => (
            <BoxSkeleton key={index} styles={{ ...commonStyles, height: 60 }} />
          ))}
        </div>
        {[...Array(8)].map((_, i) => renderSearchItem())}
      </div>
      <div className="w-[30%] h-full m-1 rounded-md overflow-hidden">
        <div className="border overflow-hidden rounded-md animate-mildFade h-[500px]">
          <BarChartSkeleton />
        </div>
      </div>
    </div>
  );
};

export default SearchSkeleton;
