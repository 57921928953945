import Svg from "components/common/svg"
import { useState, useEffect, ReactNode } from "react"
import Button from "@common/buttons/button"
import { isFunction } from "@common/lib/util"
type SearchBarProps = {
  onChange: (text: string) => void
  onSubmit?: (text: string) => void
  renderHelpContent?: () => ReactNode
  value?: string
  onClearInput?: () => void
  placeholder?: string
}

export default function SearchBar({
  onChange,
  onSubmit,
  renderHelpContent,
  value,
  onClearInput,
  placeholder = "Search",
}: SearchBarProps) {
  const [text, setText] = useState("")

  useEffect(() => {
    setText(value || "")
  }, [value])

  const handleChange = (e: any) => {
    const text = e.target.value
    setText(text)
    if (onChange) onChange(text)
  }

  return (
    <div className="flex-1 flex">
      <form className="w-full flex md:ml-0" onSubmit={(e) => handleSubmit(e)}>
        <label htmlFor="search_field" className="sr-only">
          Search
        </label>
        <div className="relative w-full text-muted-foreground focus-within:text-primary">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <Svg name="search" classes="h-5 w-5" />
          </div>
          <input
            value={text}
            autoComplete="off"
            // autoFocus
            onChange={handleChange}
            id="search_field"
            className="bg-transparent  border-transparent focus:border-transparent focus:ring-transparent block w-full h-full pl-8 pr-3 py-2 rounded-md text-primary placeholder:text-muted-foreground  sm:text-sm"
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.code == "Space") e.stopPropagation()
            }}
            type={onClearInput ? "text" : "search"}
          />
          {value && onClearInput && (
            <div className="absolute inset-y-0 right-0 flex items-center cursor-pointer transform hover:scale-110 ">
              <Button variant="minimal" type="button" onClick={onClearInput}>
                <Svg name="cross" classes=" h-5 w-5 " tooltipContent={undefined} />
              </Button>
            </div>
          )}
        </div>
      </form>
      {renderHelpContent && isFunction(renderHelpContent)
        ? renderHelpContent()
        : null}
    </div>
  )

  function handleSubmit(e: any) {
    e.preventDefault()
    if (onSubmit) onSubmit(text)
    // setText("")
  }
}
