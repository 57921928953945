// Internal Common Imports
import Message from "@common/message"
import Svg from "@common/svg"
import Tooltip from "@common/tooltip/tooltip"
import { ReactNode } from "react"
import { VariantProps, cva } from "class-variance-authority"

// Base props
interface LabelBaseProps {
  label?: ReactNode
  children?: ReactNode
  labelDescription?: string
  labelPosition?: "top" | "left" | "right"
  required?: boolean
  labelInfo?: ReactNode
  onLabelActionClick?: () => void
  handleError?: boolean
  error?: ReactNode
  type?: string
  id?: string
}
// Position Variants
const labelPositionVariants = cva("gap-2 flex", {
  variants: {
    position: {
      top: "flex-col",
      left: "items-center grid grid-cols-6",
      right: "items-center flex-row-reverse justify-end",
    },
  },
  defaultVariants: {
    position: "top",
  },
})

// Font Variants
const labelFontVariants = cva(
  "items-center text-primary flex text-sm space-x-2 select-none w-full",
  {
    variants: {
      font: {
        normal: "font-normal",
        semibold: "font-semibold",
      },
    },
    defaultVariants: {
      font: "semibold",
    },
  }
)

export interface LabelProps
  extends VariantProps<typeof labelPositionVariants>,
    VariantProps<typeof labelFontVariants>,
    LabelBaseProps {}

export default function Label({
  label,
  children,
  labelDescription,
  labelPosition,
  required,
  labelInfo,
  handleError,
  error,
  id,
  font,
}: LabelProps) {
  // Handle label action click

  return (
    <div className={labelPositionVariants({ position: labelPosition })}>
      {label && (
        <div className="relative col-span-2">
          <label
            htmlFor={id}
            style={{
              overflowWrap: "anywhere",
            }}
            className={labelFontVariants({ font })}
          >
            {label} {required && <span className="mx-1">*</span>}
            {labelInfo && <span className="mx-1">{renderLabelTooltip()}</span>}
          </label>
          {renderLabelDescription(labelDescription)}
        </div>
      )}
      <div className={labelPosition === "left" ? "col-span-3" : ""}>
        {children}
        {error && handleError && (
          <div className="mt-1">
            <Message size="xs" message={error} />
          </div>
        )}
      </div>
    </div>
  )

  function renderLabelTooltip() {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <Svg name="question-mark-circle" classes="w-4 h-4 text-secondary" />
        </Tooltip.Trigger>
        <Tooltip.Content>{labelInfo}</Tooltip.Content>
      </Tooltip>
    )
  }

  function renderLabelDescription(description?: string) {
    return (
      description && (
        <div className="text-sm-r font-normal text-secondary">{description}</div>
      )
    )
  }
}

/**
 * TEST CASES:
 * 1. Label shrink behaviour in lg, md & sm 
      ex case 1: very large single word should split to fit
                |Occaecatdeseruntsit|

                |Occaecatde|
                |seruntsit |

      ex case 2: sentenses should split between words to fit at first then exhibit case 1 as space shrinks further
                | Culpa culpa magna ut exercitation tempor reprehenderit|

                | Culpa culpa magna ut exercitation|
                |tempor reprehenderit              |

                |Culpa culpa |
                |magna ut exe|
                |rcitation   |
                |tempor      |
                |reprehenderi|
                |t           |

 */
