import React from "react"
import { isFunction } from "@common/lib/util"
import Label from "@common/forms/label"
import { CheckboxStyledProps } from "@common/forms/inputs/types"

const CheckboxStyled = ({
  indeterminate,
  label={},
  required,
  id,
  ...rest
}: CheckboxStyledProps) => {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate])

  return (
    <Label {...label} required={required} id={id}>
      <div className="flex items-center">
        <input
          {...rest}
          ref={ref}
          className="text-primary dark:text-muted  bg-transparent cursor-pointer disabled:opacity-60 disabled:cursor-not-allowed focus:ring-0 focus:ring-offset-0 border-border focus:ring-transparent"
          type="checkbox"
          id={id}
          required={required}
          readOnly={!isFunction(rest.onChange)}
        />
      </div>
    </Label>
  )
}

export default CheckboxStyled
