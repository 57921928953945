import React from "react"
import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"
import CellSkeleton from "@common/skeletons/skeletonComponents/cellSkeleton"
import ChannelsPageSkeleton from "@common/skeletons/skeletonComponents/channelsPageSkeleton"
import EntityGroupSkeleton from "@common/skeletons/skeletonComponents/entityGroupSkeleton"
import FlatCardsSkeleton from "@common/skeletons/skeletonComponents/flatCardsSkeleton"
import FormSkeleton from "@common/skeletons/skeletonComponents/formSkeleton"
import { HorizontalNavSkeleton } from "@common/skeletons/skeletonComponents/horizontalNavSkeleton"
import ListSkeleton from "@common/skeletons/skeletonComponents/listSkeleton"
import MetricSkeleton from "@common/skeletons/skeletonComponents/metricSkeleton"
import PaletteSkeleton from "@common/skeletons/skeletonComponents/paletteSkeleton"
import ReportsPageSkeleton from "@common/skeletons/skeletonComponents/reportsPageSkeleton"
import SearchSkeleton from "@common/skeletons/skeletonComponents/searchSkeleton"
import SectionsSkeleton from "@common/skeletons/skeletonComponents/sectionsSkeleton"
import BarChartSkeleton from "@common/skeletons/skeletonComponents/barChartSkeleton"
import TableSkeleton from "@common/skeletons/skeletonComponents/tableSkeleton"
import ThreeCardsSkeleton from "@common/skeletons/skeletonComponents/threeCardsSkeleton"
import { VerticalNavSkeleton } from "@common/skeletons/skeletonComponents/verticalNavSkeleton"
import WriterSkeleton from "@common/skeletons/skeletonComponents/writerSkeleton"
import SidepanelSkeleton from "@common/skeletons/skeletonComponents/sidepanelSkeleton"

const skeletons = {
  BoxSkeleton,
  CellSkeleton,
  ChannelsPageSkeleton,
  EntityGroupSkeleton,
  FlatCardsSkeleton,
  FormSkeleton,
  HorizontalNavSkeleton,
  ListSkeleton,
  MetricSkeleton,
  PaletteSkeleton,
  ReportsPageSkeleton,
  SearchSkeleton,
  SectionsSkeleton,
  BarChartSkeleton,
  TableSkeleton,
  ThreeCardsSkeleton,
  VerticalNavSkeleton,
  WriterSkeleton,
  SidepanelSkeleton,
}

type SkeletonName = keyof typeof skeletons

interface SkeletonProps {
  name: SkeletonName
  [key: string]: any
}

const Skeleton = ({ name, ...otherProps }: SkeletonProps) => {
  const SkeletonComponent = skeletons[name]
  if (!SkeletonComponent) {
    console.error(`Skeleton component ${name} not found.`)
    return null
  }
  return <SkeletonComponent {...otherProps} />
}

export default Skeleton
