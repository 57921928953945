// External library imports
import React, { createContext, ReactNode } from "react"
import { VariantProps, cva } from "class-variance-authority"
import { type Placement as PopperPlacement } from "@popperjs/core"

// Internal library imports
import usePopperPosition from "./hooks/usePopperPosition"
import { Portal } from "@headlessui/react"
import { joinClassNames } from "@common/lib/util"

// Context
const PopoverContext = createContext<any>(undefined)

export type PopoverProps = VariantProps<typeof popoverVariants> & {
  children: ReactNode
  isClickType?: boolean
  position?: PopperPlacement
  strategy?: "fixed" | "absolute"
  onVisibleChange?: (visibility: boolean) => void
}

// Popover variants
const popoverVariants = cva(
  "bg-background w-max border rounded-md shadow-sm outline-none  z-[999999]"
)

// Components
export const Popover = ({
  children,
  isClickType = true,
  position = "bottom-end",
  strategy = "fixed",
  onVisibleChange,
}: PopoverProps) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, getArrowProps } =
    usePopperPosition(position, isClickType, strategy, true, onVisibleChange)

  return (
    <PopoverContext.Provider
      value={{
        bodyClasses: popoverVariants(),
        setTriggerRef,
        setTooltipRef,
        getTooltipProps,
        getArrowProps,
        visible,
        strategy,
      }}
    >
      {children}
    </PopoverContext.Provider>
  )
}

const PopoverButton = ({
  children,
  onClick,
  full,
}: {
  children: ReactNode
  onClick?: (event: any) => void
  full?: boolean
}) => (
  <PopoverContext.Consumer>
    {(context) =>
      context && (
        <div
          ref={context.setTriggerRef}
          onClick={onClick}
          // removed z-[9999] from className below
          className={joinClassNames(
            "focus:outline-none  cursor-pointer",
            full ? "" : "w-fit "
          )}
        >
          {children}
        </div>
      )
    }
  </PopoverContext.Consumer>
)

const PopoverPanel = ({ children }: { children: ReactNode }) => (
  <PopoverContext.Consumer>
    {({ bodyClasses, setTooltipRef, getTooltipProps, visible, strategy }) =>
      visible &&
      (strategy === "fixed" ? (
        <Portal>
          <PopoverBody
            bodyClasses={bodyClasses}
            setTooltipRef={setTooltipRef}
            getTooltipProps={getTooltipProps}
          >
            {children}
          </PopoverBody>
        </Portal>
      ) : (
        <PopoverBody
          bodyClasses={bodyClasses}
          setTooltipRef={setTooltipRef}
          getTooltipProps={getTooltipProps}
        >
          {children}
        </PopoverBody>
      ))
    }
  </PopoverContext.Consumer>
)

Popover.Button = PopoverButton
Popover.Body = PopoverPanel

/**
 *
 * @private
 */
function PopoverBody({
  bodyClasses,
  setTooltipRef,
  getTooltipProps,
  children,
}: {
  bodyClasses: string
  setTooltipRef: any
  getTooltipProps: any
  children: ReactNode
}) {
  return (
    <div className={bodyClasses} ref={setTooltipRef} {...getTooltipProps()}>
      {children}
    </div>
  )
}
