import Svg from "./svg"
import { ErrorCodes } from "@common/lib/forms.utils"

// size - xs, sm (default), lg
// success = true/false (default false)

export default function Message({ size = "", message, success = false }) {
  const textSize = size == "xs" ? "text-xs" : size == "lg" ? "text-lg" : "text-sm"
  const svgSize = size == "xs" ? "w-4 h-4" : "w-6 h-6"
  const svgIcon = success == true ? "tick" : "warning"
  const color = success == true ? "text-green-500" : "text-destructive"

  return (
    <div className={"flex items-center animate-slideDown " + textSize + " " + color}>
      <Svg name={svgIcon} classes={svgSize + " mr-1"} />
      <div>{typeof message == "string" ? message : ErrorCodes["GENERAL"]}</div>
    </div>
  )
}
